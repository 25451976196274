/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import ServiceContainer from '@flashpointbv/solar-service-container';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
//TODO: Add type definitions for vue-directive-tooltip
// @ts-ignore
import Tooltip from 'vue-directive-tooltip';
import {UICoreSticky, UICoreOffCanvas, UICoreBlazeSlider} from '@flashpointbv/solar-ui-core';
import Environment from '@flashpointbv/solar-ui-environment';

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);
new UICoreBlazeSlider();

Vue.use(VueI18n);
Vue.use(VueLazyload);
Vue.use(Vuex);
Vue.use(Tooltip);

import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substr(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {CatalogCategory, CatalogProduct, CatalogGroupedProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog/src';
import {CheckoutGlobal, CheckoutCart, CheckoutCheckout, CheckoutShipping, CheckoutPayment, CheckoutTotals} from '@flashpointbv/solar-ui-checkout/dist';
import CustomerWishlist from '../../../../../vendor/flashpoint/solar-wishlist/resources/resources/assets/js/stores/wishlist';
import MeasurementProtocol from '../../../../../vendor/happy-horizon/ga-measurement-protocol/resources/assets/js/stores/MeasurementProtocol';
import CatalogBundleProduct from './stores/modules/views/catalog/product/bundle/index';
import GlobalMessages from './stores/modules/messages';
import CatalogPageBuilder from '../../../../../vendor/happy-horizon/page-builder-tweakwise-landing-pages/resources/resources/assets/js/stores/page-builder';

const store = new Vuex.Store({
  strict: false,
});

store.registerModule('GlobalMessages', GlobalMessages);

store.registerModule('CatalogCategory', CatalogCategory);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);

store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutCart', CheckoutCart);
store.registerModule('CheckoutCheckout', CheckoutCheckout);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutTotals', CheckoutTotals);

store.registerModule('CustomerWishlist', CustomerWishlist);

store.registerModule('MeasurementProtocol', MeasurementProtocol);

store.registerModule('CatalogPageBuilder', CatalogPageBuilder);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
  {key: 'footer-subscribe', concrete: () => import('./components/FooterSubscribe.vue')},
  {key: 'header-wishlist', concrete: () => import('./components/HeaderWishlist.vue')},
  {key: 'header-cart', concrete: () => import('./components/HeaderCart.vue')},
  {key: 'customer-wishlist-index', concrete: () => import('./components/customer/wishlist/Index.vue')},
  {key: 'customer-wishlist-banner', concrete: () => import('./components/customer/wishlist/WishlistBanner.vue')},
  {key: 'image-slider', concrete: () => import('./components/ImageSlider.vue')},
  {key: 'sub-categories-slider', concrete: () => import('./components/SubCategoriesSlider.vue')},
  {key: 'global-messages', concrete: () => import('./components/GlobalMessages.vue')},
  {key: 'tweakwise-recommendation', concrete: () => import('../../../../../vendor/happy-horizon/tweakwise-recommendations/resources/resources/assets/js/components/TweakwiseRecommendations.vue')},

  //Autocomplete
  {key: 'search-autocomplete', concrete: () => import('./components/search/SearchForm.vue')},
  {key: 'search-autocomplete-list-item', concrete: () => import('./components/search/AutocompleteListItem.vue')},
  {key: 'search-result-suggested-categories', concrete: () => import('./components/search/results/SearchResultSuggestedCategories.vue')},
  {key: 'search-result-suggested-products', concrete: () => import('./components/search/results/SearchResultSuggestedProducts.vue')},
  {key: 'search-result-suggested-keywords', concrete: () => import('./components/search/results/SearchResultSuggestedKeywords.vue')},
  {key: 'search-result-suggested-filters', concrete: () => import('./components/search/results/SearchResultSuggestedFilters.vue')},

  //Blocks
  {key: 'block-product-row', concrete: () => import('./components/blocks/ProductRow.vue')},

  //Catalog
  {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},

  //Account
  {key: 'login', concrete: () => import('./components/account/Login.vue')},
  {key: 'customer-merge-message', concrete: () => import('./components/customer/MergeMessage.vue')},

  //Catalog
  {key: 'catalog-category-view', concrete: () => import('./views/catalog/CatalogCategoryView.vue')},
  {key: 'catalog-search-view', concrete: () => import('./views/catalog/CatalogSearchView.vue')},

  {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue')},
  {key: 'active-filter', concrete: () => import('./components/catalog/filter/ActiveFilter.vue')},
  {key: 'attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
  {key: 'swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
  {key: 'price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},
  {key: 'sub-categories', concrete: () => import('./components/catalog/filter/SubCategories.vue')},
  {key: 'catalog-product-list-toolbar-active-filters', concrete: () => import('./components/catalog/ProductActiveFilterList.vue')},

  {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
  {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/ProductListToolbar.vue')},
  {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
  {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/ProductListToolbarSortSelect.vue')},
  {key: 'catalog-product-list-toolbar-size-select', concrete: () => import('./components/catalog/ProductListToolbarSizeSelect.vue')},
  {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('./components/catalog/ProductListToolbarPaginator.vue')},
  {key: 'catalog-related-items-slider', concrete: () => import('./components/catalog/RelatedItemsSlider.vue')},
  {key: 'catalog-product-sticky-add-to-cart', concrete: () => import('./components/catalog/product/StickyAddToCart.vue')},
  {key: 'catalog-product-add-to-cart', concrete: () => import('./components/catalog/product/ProductAddToCart.vue')},
  {key: 'catalog-product-list-banner', concrete: () => import('./components/catalog/ProductListBanner.vue')},

  {key: 'catalog-brands-popular-brands-slider', concrete: () => import('./components/catalog/brands/PopularBrandSlider.vue')},

  //Catalog Filters
  {key: 'catalog-product-list-attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
  {key: 'catalog-product-list-swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
  {key: 'catalog-product-list-price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},

  //Catalog Product
  {key: 'catalog-product-configuration', concrete: () => import('./components/catalog/product/ProductConfiguration.vue')},
  {key: 'catalog-product-blog', concrete: () => import('./components/catalog/product/ProductBlog.vue')},
  {key: 'catalog-product-price', concrete: () => import('./components/catalog/product/ProductPrice.vue')},
  {key: 'catalog-product-countdown', concrete: () => import('./components/catalog/product/ProductCountDownTimer.vue')},
  {key: 'catalog-product-media', concrete: () => import('./components/catalog/product/ProductMedia.vue')},
  {key: 'catalog-product-media-fullscreen', concrete: () => import('./components/catalog/product/ProductMediaFullscreen.vue')},
  {key: 'catalog-product-simple', concrete: () => import('./components/catalog/product/simple/ProductTypeSimple.vue')},
  {key: 'catalog-product-configurable', concrete: () => import('./components/catalog/product/configurable/ProductTypeConfigurable.vue')},
  {key: 'catalog-product-configurable-select', concrete: () => import('./components/catalog/product/configurable/type/OptionSelect.vue')},
  {key: 'catalog-product-configurable-swatch', concrete: () => import('./components/catalog/product/configurable/type/OptionSwatch.vue')},
  {key: 'catalog-product-bundle', concrete: () => import('./components/catalog/product/bundle/ProductTypeBundle.vue')},
  {key: 'catalog-product-bundle-select', concrete: () => import('./components/catalog/product/bundle/type/BundleSelect.vue')},
  {key: 'catalog-product-bundle-radio', concrete: () => import('./components/catalog/product/bundle/type/BundleRadio.vue')},
  {key: 'catalog-product-bundle-checkbox', concrete: () => import('./components/catalog/product/bundle/type/BundleCheckbox.vue')},
  {key: 'catalog-product-grouped', concrete: () => import('./components/catalog/product/grouped/ProductTypeGrouped.vue')},
  {key: 'catalog-product-grouped-product', concrete: () => import('./components/catalog/product/grouped/ProductTypeGroupedProduct.vue')},

  //Cart
  {key: 'checkout-cart', concrete: () => import('./components/checkout/cart/Cart.vue')},
  {key: 'checkout-cart-items', concrete: () => import('./components/checkout/cart/CartItems.vue')},
  {key: 'checkout-cart-items-list', concrete: () => import('./components/checkout/cart/CartItemsList.vue')},
  {key: 'checkout-cart-totals', concrete: () => import('./components/checkout/cart/CartTotals.vue')},
  {key: 'checkout-cart-crosssells', concrete: () => import('./components/checkout/cart/ProductCrosssellList.vue')},

  //Checkout
  {key: 'checkout-checkout', concrete: () => import('./components/checkout/checkout/Checkout.vue')},
  {key: 'checkout-newsletter', concrete: () => import('./components/checkout/checkout/CheckoutNewsletter.vue')},

  {key: 'checkout-address-detail', concrete: () => import('./components/checkout/checkout/step/AddressDetail.vue')},
  {key: 'checkout-address-shipping', concrete: () => import('./components/checkout/checkout/address/ShippingAddress.vue')},
  {key: 'checkout-address-billing', concrete: () => import('./components/checkout/checkout/address/BillingAddress.vue')},
  {key: 'checkout-address-login', concrete: () => import('./components/checkout/checkout/address/CheckoutLogin.vue')},
  {key: 'checkout-address-login-form', concrete: () => import('./components/checkout/checkout/address/CheckoutLoginForm.vue')},
  {key: 'checkout-address-autocomplete', concrete: () => import('./components/checkout/checkout/address/input/AddressAutocomplete.vue')},
  {key: 'checkout-address-form', concrete: () => import('./components/checkout/checkout/address/input/AddressForm.vue')},
  {key: 'checkout-address-select', concrete: () => import('./components/checkout/checkout/address/input/AddressSelect.vue')},
  {key: 'checkout-address-option', concrete: () => import('./components/checkout/checkout/address/input/AddressOption.vue')},

  {key: 'checkout-shipping-detail', concrete: () => import('./components/checkout/checkout/step/ShippingDetail.vue')},
  {key: 'checkout-shipping-methods-default', concrete: () => import('./components/checkout/checkout/shipping/methods/Default.vue')},

  {key: 'checkout-payment-detail', concrete: () => import('./components/checkout/checkout/step/PaymentDetail.vue')},
  {key: 'checkout-payment-methods-default', concrete: () => import('./components/checkout/checkout/payment/methods/Default.vue')},

  {key: 'checkout-summary', concrete: () => import('./components/checkout/checkout/summary/Summary.vue')},
  {key: 'checkout-totals', concrete: () => import('./components/checkout/checkout/summary/CheckoutTotals.vue')},
  {key: 'checkout-coupon-form', concrete: () => import('./components/checkout/checkout/coupon/CouponForm.vue')},

  //Reviews
  {key: 'product-reviews', concrete: () => import('./components/catalog/product/ProductReviews.vue')},
  {key: 'product-review-item', concrete: () => import('./components/catalog/product/ProductReviewItem.vue')},
  {key: 'catalog-product-review-form', concrete: () => import('./components/catalog/product/ProductReviewForm.vue')},
  //Store
  {key: 'store-list', concrete: () => import('./components/store/StoreList.vue')},
  {key: 'store-detail', concrete: () => import('./components/store/StoreDetail.vue')},

  //Sliders and swipers
  {key: 'swiper', concrete: () => import('./shaked-exports/swiper/swiper')},
  {key: 'swiper-slide', concrete: () => import('./shaked-exports/swiper/swiper-slide')},

  //Lazy load components
  {key: 'lazy-load', concrete: () => import('./components/LazyLoad.vue')},

  //Page Builder
  {key: 'catalog-page-builder-view', concrete: () => import('./views/catalog/CatalogPageBuilderView.vue')},
]);

import nl_NL from './i18n/nl_NL';
import en_GB from './i18n/en_GB';

import {localize} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import nl from 'vee-validate/dist/locale/nl.json';

localize({
  en,
  nl
});

localize(`vee_${window.Locale}`);

const messages = {
  nl_NL,
  en_GB
};

const i18n = new VueI18n({
  locale: window.Locale,
  fallbackLocale: 'nl_NL',
  formatFallbackMessages: true,
  silentTranslationWarn: true,
  messages
});

new Vue({
  store,
  i18n,
  components: ServiceContainer().getComponents(),
  data: {
    csrfToken: window.csrfToken
  },
  async created() {
    await this.$store.dispatch('CheckoutGlobal/initialize');

    await this.$store.commit('CheckoutCart/SET_DEFAULT_CARRIER_DATA', {
      methodCode: window.Config['default_method_code'],
      carrierCode: window.Config['default_carrier_code'],
    });

    this.$eventBus.$on('item-rendered', () => {
      const catalogCategoryProductPlaceholders = document.querySelector('.category__placeholder');
      if (catalogCategoryProductPlaceholders) {
        (catalogCategoryProductPlaceholders as HTMLElement).style.display = 'none';
      }
    });
  }
}).$mount('#solar-app');
