export default {
  'No results found for {inputQuery}': 'Geen resultaten gevonden voor {inputQuery}',
  'More results for {inputQuery}': 'Meer resultaten voor {inputQuery}',
  'Show more': 'Toon meer',
  'Show {count} products': 'Toon {count} producten',
  'Show results': 'Toon resultaten',
  'Show less': 'Minder opties',
  '{count} results': 'Geen resultaten | 1 resultaat | {count} resultaten',
  'Show {totalItems} results': 'Geen resultaten | Toon 1 resultaat | Toon {totalItems} resultaten',
  'Show {totalItems} products': 'Geen resultaten | Toon 1 resultaat | Toon {totalItems} producten',
  'No products were found in this category.': 'Er zijn geen producten gevonden binnen deze categorie.',
  'Hit enter or try another search.': 'Druk op enter of probeer een andere zoekopdracht',
  'Searching': 'Zoeken',

  //Header cart
  "Product has been added to cart": "Product toegevoegd aan winkelwagen",
  "{productName} has been added to your shopping cart": "{productName} is toegevoegd aan je winkelwagen.",
  "Failed to add to cart": "Kon product niet toevoegen.",
  "The password must be at least 8 characters long, contain at least one uppercase letter and one number.": "Het wachtwoord moet minimaal 8 karakters, 1 hoofdletter en 1 cijfer bevatten.",

  //Cart
  'Cart': 'Winkelwagen',
  'Secure connection': 'Veilige verbinding',
  "Continue shopping": "Verder winkelen",
  'One or more products are out of stock.': 'Een of meer producten zijn niet op voorraad.',
  'You have no product(s) in your shopping cart.': 'Je hebt geen product(en) in je winkelwagen.',
  'Click {url} to continue shopping': 'Klik {url} om verder te winkelen',
  'To checkout': 'Naar afrekenen',
  'Loading shopping cart items...': 'Winkelwagen aan het laden...',
  'Shipping': 'Verzending',
  'Payment': 'Betaling',
  'Discount products': 'Kassakoopjes',
  'Do you have a promotional code?': 'Heb je een kortingscode?',
  'Your applied coupon code is {couponCodeInput}.': 'Je toegepaste kortingscode is {couponCodeInput}.',
  'Add to cart': 'In winkelwagen',
  'Order with longer delivery time': 'Bestel met levertijd',
  'Enter a correct value': 'Voer a.u.b. een geldige waarde in',
  'Temporarily out of order': 'Tijdelijk niet bestelbaar',
  "View included products": "Bekijk inbegrepen producten",
  "Included products": "Inbegrepen producten",
  "Quantity": "Aantal",
  "Back to cart": "Terug naar winkelwagen",
  "Shipping method is not valid": "Kies een bezorgmethode",
  "Payment method is not valid": "Kies een betaalmethode",

  //Checkout
  'Email address': 'E-mail adres',
  'Postal code': 'Postcode',
  'House number': 'Huisnummer',
  'House no.': 'Huisnr',
  'Addition': 'Toevoeging',
  'Add.': 'Toev.',
  'Street': 'Straat',
  'City': 'Plaats',
  'Name': 'Voornaam',
  'Last name': 'Achternaam',
  'Company': 'Bedrijf',
  'Country': 'Land',
  'Telephone': 'Telefoonnummer',
  'Subject': 'Onderwerp',
  'Did you mean {email}?': 'Bedoelde je {email}?',
  'Click here to automatically retrieve your address': 'Klik hier om je adres automatisch op te vragen',
  'We have found the following address': 'We hebben het volgende adres gevonden',
  'Invalid address. Are you sure it is correct?': 'Ongeldig adres. Weet je zeker dat het juist is ingevuld?',
  'Click here to enter the address manually.': 'Klik hier om het adres handmatig in te voeren.',
  'Billing address': 'Factuurgegevens',
  'Shipping address': 'Je bezorgadres',
  'Add new address': 'Nieuw adres toevoegen',
  'Select your address': 'Selecteer je gewenste adres',
  'Create account': 'Account aanmaken',
  'Password': 'Wachtwoord',
  'Confirm password': 'Wachtwoord bevestigen',
  'Log in': 'Inloggen',
  'Forgot password?': 'Wachtwoord vergeten?',
  'Bank': 'Bank',
  'Telefoonnummer is not valid': 'Telefoonnummer is niet geldig',
  'Wachtwoord bevestigen is not valid': 'Het ingevoerde wachtwoord komt niet overeen',
  'E-mail adres is not valid': 'E-mail is niet geldig',
  'Voornaam is not valid': 'Voornaam is niet geldig',
  'Achternaam is not valid': 'Achternaam is niet geldig',
  'Postcode is not valid': 'Postcode is niet geldig',
  'Huisnummer is not valid': 'Huisnummer is niet geldig',
  'Creditcard': 'Creditcard',
  'Are you an existing customer?': 'Heb je al een account?',
  'Login here': 'Hier inloggen',
  'Billing address is different from the address above': 'Factuuradres is anders dan bovenstaand adres',
  'Payment methods': 'Betalen',
  'Payment method': 'Betaalmethodes',
  'Shipping method': 'Bezorgen',
  'Edit address details': 'Wijzig adresgegevens',
  'Select payment method': 'Naar betalen',
  'Select shipping method': 'Naar bezorgen',
  'Start payment': 'Afrekenen',
  'By clicking start payment you agree to our {url}': 'Door op afrekenen te klikken, ga je akkoord met onze {url}',
  'Terms & conditions': 'Algemene voorwaarden',
  'Your details': 'Je gegevens',
  'Your address details': 'Je adresgegevens',
  'Select payment': 'Selecteer betaalmethode',
  'Select shipping': 'Selecteer bezorgmethode',
  'Checkout': 'Afrekenen',
  'incl. VAT': 'incl. BTW',
  'Prefix': 'Aanhef',
  'Subtotal': 'Subtotaal',
  'Our price': 'Onze prijs',
  'Total saving': 'Je bespaart',
  'Needed for free shipping': 'Nodig tot gratis verzending',
  'Grand Total': 'Eindtotaal',
  'Mr.': 'Dhr.',
  'Mrs.': 'Mevr.',
  'Summary': 'Overzicht',
  'There are no products in your wishlist': 'Je hebt nog geen favorieten toegevoegd.',
  'Price': 'Prijs',
  'The coupon code isn\'t valid. Verify the code and try again.': 'De ingevulde kortingscode is niet geldig',
  'Bank is not valid': 'Kies een bank om door te gaan',
  'Please enter a valid code': 'Vul een geldige kortingscode in',
  "Surcharge": "Betaalkosten",
  "Suggestions": "Suggesties",
  "Categories": "Categorieën",
  "Filters": "Merken",
  "Show all results for": "Toon alle zoekresultaten voor:",
  "You have an account. Would you like to {action}?": "Je hebt al een account. Wil je {action}?",
  "login": "inloggen",
  "Total retail price": "Adviesprijs totaal",
  "Please wait, we are updating your shopping cart...": "Even geduld, we zijn je winkelwagen aan het bijwerken...",
  "You can now view and adjust your shopping cart.": "Je kunt je volledige winkelwagen nu bekijken en aanpassen.",
  "Your shopping cart has been updated! The items in your current shopping cart have now been merged with the shopping cart associated with your account.": "Je winkelwagen is bijgewerkt! De items in je huidige winkelwagen zijn nu samengevoegd met de winkelwagen die aan je account is gekoppeld. Dit betekent dat artikelen uit beide winkelwagens zijn gecombineerd.",
  "Check your emailaddress before you order, so you you don't miss any order updates!": "Controleer je e-mailadres voor je bestelt, zo mis je geen bestelupdate!",
  //Validation, errors and utility
  'Clear all': 'Alles wissen',
  'Apply': 'Toepassen',
  'Cancel': 'Annuleren',
  'Close': 'Sluiten',
  'here': 'hier',
  'Free': 'Gratis',

  //Product and category
  "Ends in": "Eindigt over",


  //Rest
  'Your email': 'Je e-mailadres',
  'Subscribe to the newsletter and never miss a promotion!': 'Schrijf je in voor de nieuwsbrief en mis geen enkele actie!',
  'subscribe': 'Inschrijven',
  'The password needs at least %1 characters. Create a new password and try again.': 'Het wachtwoord heeft minstens 7 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.',

  //Account
  'My wishlist': 'Mijn wensenlijst',
  "{url} for optimal function of the wishlist": "{url} voor optimale werking van de wensenlijst",
  "Login Wishlist": "Log in",

  //Wishlist
  "My favorites": "Mijn favorieten",
  "New to Mamaloes?": "Nieuw bij Mamaloes?"
}
